.OrderTags {
    margin-top: 20px;

    .ListTags {
        padding: 0px;

        .Tag {
            background: #dfe3e8;
            border-radius: 10px;
            padding: 4px 10px 4px 10px;
            font-size: 15px;
            list-style: none;
            display: inline-block;
            margin-top: 5px;

            .Close {
                margin-left: 10px;
                font-size: 16px;
                cursor: pointer;
            }
        }
    }
}