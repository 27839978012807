.TableImporterJobs {
    table {
        tr {
            min-width: 100%;
        }

        thead {
        }

        tbody {
        }
    }
}