.ProductsContainer {
    .TableNav {
        .Previous, .Next {
            span:first-child {
                display: none;
            }

            span.sr-only {
                position: initial;
                width: auto;
                height: auto;
                clip: initial;
            }
        }
    }
}
