.LabelFilter {
  display: flex;
  align-items: baseline;
  font-size: .875rem;
  font-weight: 500;

  ul.FilterItems {
    > li {
      border: solid 1px $border-color;
      display: inline-block;
      cursor: pointer;
      transition: all .3s ease;

      &:first-child {
        border-radius: 4px 0 0 4px;
      }

      &:last-child {
        border-radius: 0 4px 4px 0;
      }

      &:not(:first-child) {
        margin-left: -1px;
      }

      &.active {
        border-color: $default-primary;
        color: $default-primary;
        position: relative;
        z-index: 2;
      }
    }
  }
}