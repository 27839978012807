.CollectionsColumn {
    .ListCollections {
        padding: 0;
        margin: 0;

        li {
            margin-bottom: 5px;
            margin-right: 5px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

