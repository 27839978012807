.OrderItemsUnfulfilled {
    margin-top: 20px;
    .Item {
        margin: 0px;

        a {
            display: flex;
        }
        .ItemBody {
            margin: 0px;
            border: none;
        }
    }

    .img-popover {
        position: absolute;
        z-index: 100;
        max-height: 500px;
        max-width: 500px;
    }
}