.NavbarLeft {
    position: fixed;
    top: 0;
    bottom: 0;
    width: $offscreen-size;
    overflow: hidden;
    background-color: #fff;
    transition: all .2s ease;
    z-index: 1000;
    border-right: solid 1px $border-color;

    .nav-link {
        display: flex;
        align-items: baseline;
        font-size: 1rem;
        font-weight: 500;
        padding: 5px 15px;
        position: relative;
        white-space: nowrap;
        color: $default-text-color;
        transition: all 0.3s ease;

        &:hover, &.active {
            color: $default-primary;
            text-decoration: none;
        }
    }

    .IconHolder {
        border-radius: 6px;
        display: inline-block;
        font-size: 17px;
        height: 35px;
        left: 0;
        line-height: 35px;
        position: relative;
        text-align: center;
        width: 35px;
    }

    .NavLogo {
        border-bottom: solid 1px $border-color;

        .SiteName {
            color: $grey-900;
            font-weight: 500;
            margin-left: 5px;
            font-size: 1.09375rem;
            vertical-align: middle;
        }

        .LinkLogo {
            text-decoration: none;
            display: flex;
            align-items: center;
        }

        .LogoImage {
            flex: 0 0 66px;

            img {
                width: 100%;
                padding: 10px;
            }
        }
    }

    .PrimaryMenu {
        margin-top: 10px;

        .Title {
            display: inline-block;
            margin-left: 14px;
        }
    }
}
