.TableLength {
  color: $default-dark;
  font-weight: 400;

  select {
    border: 1px solid $border-color;
    border-radius: 2px;
    box-shadow: none;
    height: 40px;
    font-size: 14px;
    padding: 5px;
    margin-left: 5px;
    margin-right: 5px;
    color: $default-text-color;
    transition: all 0.2s ease-in;
  }
}