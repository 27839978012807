$start: 0;
$end: 12;

.OrdersTable {
    .orders-table {
        table-layout: fixed;
        word-wrap: break-word;
        @for $i from $start through $end {
            .column-#{$i} {
                width: percentage($i / 12)
            }
        }
    }

    .product-item, .product-item:hover {
        color: unset;
    }

    .Table {
        margin-top: 20px;
    }

    .TotalItems {
        float: right;
        font-weight: 700;
        color: #313435;
    }
}

.Table {
    border-collapse: collapse;
    border-radius: 6px 6px 0 0;
}

.TableHead {
    > tr {
        > th {
            background: #fafafa;
            color: #535353;
            border-bottom: 1px solid #e8e8e8;
        }
    }
}
