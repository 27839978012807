.StoresPage {
  min-height: 100vh;
  background-color: #f9fafb;

  .PageTitle {
    margin-bottom: 20px;
  }

  .StoresPageInner {
    background-color: #fff;
    padding: 60px 40px;
    width: 700px;
    position: relative;
    top: 40px;
    border: solid 1px $border-color;
  }
}