.StatusEdit {
    margin-bottom: 1rem;

    .ChangeStatus {
        .Link {
            color: #637381;
        }

        .StatusButton {
            padding: 0.375rem 0.75rem;
            color: #637381;
            outline: none;
            margin-right: 1rem;
            border: 0;
            cursor: pointer;

            &:hover {
                background-color: rgba(33,43,54,0.1);
            }

            .Title {
                margin-right: 0.5rem;
            }
            position: relative;
        }

        .Focus {
            background-color: rgba(33,43,54,0.1);
        }

        .StatusWrapper {
            position: absolute;
            z-index: 99;
            width: max-content;
            transition: transform .1s;
            top: 2.5rem;
            left: 0;

            .Card {
                width: fit-content;

                .CardBody {
                    padding: .375rem 0;

                    .StatusList {
                        padding: 0;
                        margin: 0;
                        list-style: none;

                        .Status {
                            padding: .375rem .75rem;
                            text-align: left;

                            &:hover {
                                background-color: #2196f3;
                                color: #fff;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }

        .Hidden {
            transform: scale(0);
        }

        .Background {
            z-index: 98;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}
