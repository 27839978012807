.CollectionsEdit {
    position: relative;

    .Form {
        z-index: 99;
    }

    .CollectionSuggestion {
        .Card {
            z-index: 99;
            width: 100%;
            position: absolute;
            overflow: hidden;

            .Wrapper {
                padding: 0;
                min-height: 1rem;
                padding: 0.375rem 0;

                .List {
                    padding: 0;
                    margin: 0;
                    overflow: hidden;
                    list-style: none;
                    width: 100%;

                    .Suggestion {
                        background-color: #ffffff;
                        padding: .375rem .75rem;
                        cursor: pointer;

                        &:hover {
                            background-color: #2196f3;
                            color: #f4f6f8;
                        }
                    }

                    .Selected {
                        background-color: #2196f3 !important;
                        color: #f4f6f8;
                    }

                }
            }
        }
    }

    .Background {
        position: fixed;
        opacity: 0;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 4;
    }

    .Collections {
        padding: 0;
        margin: 0;
        width: 100%;

        .Collection {
            width: 100%;
            border-radius: 4px;
            padding: .375rem .75rem;
            display: inline-block;

            &:hover {
                background: #ebeef0;
            }

            .CloseButton {
                background-color: #f4f6f8;
                float: right;
                padding: 0 0.5rem;
                cursor: pointer;
                border-radius: 4px;

                &:hover {
                    background: #0f9d58;
                    color: #f4f6f8;
                }
            }
        }
    }
}
