.FulfillmentStatus {
    &.unfulfilled {
        .Text {
            background-color: #ffea8a;
            color: #212b36;
        }
    }

    .Text {
        font-weight: 400;
        background-color: #dfe3e8;
        color: #4c5c6c;
    }
}