.ImporterPageContainer {
    .ButtonImportProduct {
        display: inline-block;
        margin-bottom: 20px;
        float: right;

        i {
            margin-right: 10px;
        }
    }

    .TableMeta {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        margin: 30px 0 20px;

        .TotalItems {
            font-weight: 700;
            color: #313435;
            align-self: flex-end;
        }
    }
}