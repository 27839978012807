@import '~pretty-checkbox/src/pretty-checkbox.scss';

.ProductsTable {
    .ThumbnailImage {
        width: 60px;
        border: solid 1px $border-color;
        border-radius: .25rem;
    }

    .ListItem {
        width: 100%;
    }

    td {
        vertical-align: top;
    }

    .SelectBar {
        background-color: #fff !important;
        border-color: transparent;
        border-top: 1px;
        border-bottom: 2px;
        position: relative;

        .Menu {
            position: absolute;
            display: inline-block;
            width: 100%;
            top: 0;
            left: 100%;
        }
    }
}
