.TableNav {
  border-top: 1px solid #dee2e6;
  padding-top: 15px;

  .Pagination {
    display: flex;
    justify-content: flex-end;

    .page-item {
      display: inline-block;
      margin-right: 10px;
      transition: all 0.2s ease-in-out;

      &.active {
        .page-link {
          color: #fff !important;
          background: $default-primary;

          &:hover {
            color: $default-white !important;
            background: $default-primary;
          }
        }
      }

      &.disabled {
        pointer-events: none;
        opacity: .4;
      }
    }

    .page-link {
      border: none;
      color: $default-text-color !important;
      padding: 6px 12px;
      border-radius: 2px;
      cursor: pointer;
      text-decoration: none;
      display: inline-block;

      &:hover {
        color: #fff !important;
        background: $default-primary;
      }
    }
  }
}