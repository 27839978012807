.OrderFulfillmentContainer {
    margin-top: 20px;

    .Fulfillment {
        .Summary {
            margin-top: 20px;
        }
    }

    .MarkAsFulfilled {
        margin-top: 20px;

        .cursor-pointer {
            cursor: pointer;
        }

        .NumericInput {
            width: 80px;
            height: 100%;
            text-align: center;
        }
    }

    .QuantityFulfill {
        tr {
            border-bottom: 1px solid #eee;
        }

        .ItemInfo {
            margin: auto;
        }
    }
}