.ProductEditContainer {
    .Header {
        display: block;
        width: 100%;

        h1 {
            margin: 0;
        }
    }

    .BottomButtons {
        width: 100%;
        margin-top: 1.5rem;
        border-top: 1px solid #ccc;
        padding: 1.5rem 0;
        display: inline-block;

        .ButtonLeft {
            float: left;
        }


        .ButtonRight {
            float: right;
        }

        .ButtonWithIcon {
            span {
                margin: 0 0.5rem;
                &:first-child {
                    margin-left: 0;
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}
