.ProductAction {
    border-color: #0f9aee;
    position: relative;
    z-index: 99;

    .ActionButton {
        display: block;
        background-color: transparent;
        border: 1px solid #0f9aee;
        color: #0f9aee;
        padding: .5rem 1rem;
        border-radius: 4px;
        outline: none;
        z-index: 99;
    }

    .ListAction {
        min-height: 3rem;
        width: 15rem;
        position: absolute;
        top: calc(100% + 0.5rem);
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 0 0 0 1px rgba(39, 44, 48, 0.05), 0 2px 7px 1px rgba(39, 44, 48, 0.16);
        overflow: hidden;
        display: none;
        z-index: 99;

        ul {
            list-style: none;
            padding: 0;
            width: 100%;

            li {
                display: block;
                font-weight: normal;
                cursor: pointer;
                padding: 0.5rem;
                width: 100%;

                &:hover {
                    background: #007ace;
                    color: #f4f6f8;
                }
            }
        }
    }

    .Show {
        display: block;
    }

    .Background {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0;
        z-index: 98;
    }
}
