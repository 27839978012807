.OrderTableRow {
    transition: all 200ms ease-out;
    cursor: pointer;

    &:nth-of-type(odd) {
        background-color: rgba(0, 0, 0, 0.01);
    }

    &:hover {
        background-color: #f9fafb;
    }

    &.fulfillment-fulfilled {
        td {
            color: #637381;

            a {
                color: #637381;
            }
        }
    }
}
