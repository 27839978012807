.DashboardContainer {
    .DashboardInner {
        min-height: 100vh;
        padding-left: $offscreen-size;
        transition: all 0.2s ease;
    }

    .MainContentInner {
        .SectionInner {
            background-color: #fff;
            padding: 30px 15px;
            border: solid 1px $border-color;
            border-radius: 4px;
        }
    }

    .MainContent {
        padding: 85px 20px 60px;
        min-height: 100vh;
        background-color: #f9fafb;

        @include to($breakpoint-md) {
            padding: 85px 5px 5px;
        }
    }

    .nav-link .Label {
        color: #fff;
    }

    &.isCollapsed {
        .nav-link .Label {
            display: none;
        }

        .NavbarLeft {
            width: $collapsed-size;

            .Title {
                display: none;
            }
        }

        .DashboardInner {
            padding-left: $collapsed-size;
        }

        .NavbarTop {
            width: calc(100% - #{$collapsed-size});
        }
    }
}
