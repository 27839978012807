.TopButtons {
    display: inline-block;

    .LeftButtons {
        float: left;
    }

    .RightButtons {
        float: right;
        padding: 1rem 0;
    }
}
