.ImportForm {
    .BackWrapper {
        .BackButton {
            cursor: pointer;
            margin-left: 0;
            margin-right: 0;
            padding-left: 0;
            padding-right: 0;

            span {
                margin: 0 1rem;
            }

            &:hover {
                color: #007bff;
            }
        }
    }

    .SubTitle {
        font-size: .985rem;
        margin-bottom: 30px;
        margin-top: -10px;
    }

}
