.LoginPageContainer {
  background-color: #f5f5f5;
  min-height: 100vh;
  background-image: url("../statics/assets/images/login-bg.jpeg");

  .Logo {
    width: 100px;
    height: 100px;
    background-image: url("../statics/assets/images/logo.png");
    background-size: contain;
    background-repeat: no-repeat;
  }

  .LoginPageInner {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .Forms {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }


  .LoginPageInner {
    background-color: #fff;
    width: 500px;
    max-width: 100%;
    min-height: 100vh;
    text-align: center;
  }

  .PageTitle {
    font-size: 1.3125rem;
    font-weight: 300;
    color: #313435;
    margin-bottom: 30px;
    margin-top: 30px;
  }

  .SocialLogin {
    background-color: #fff;
    outline: none;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 0 30px;
    height: 40px;
    border: solid 1px $border-color;
    box-shadow: 1px 4px 5px 0px rgba(0, 0, 0, 0.1);
    transition: all .3s ease;

    .Icon {
      width: 18px;
      margin-right: 15px;
    }

    .Text {
      font-size: .875rem;
      font-weight: 700;
      color: #737373;
      line-height: 1;
    }
  }
}