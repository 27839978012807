.SaveSuccessModal {
    float: left;
    margin-top: 1rem;

    .Card {
        display: none;
        width: 100%;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .display {
        display: block;
    }
}
