@font-face {
	font-family: 'themify';
	src:url(/static/media/themify.2c454669.eot);
	src:url(/static/media/themify.2c454669.eot?#iefix-fvbane) format('embedded-opentype'),
		url(/static/media/themify.a1ecc3b8.woff) format('woff'),
		url(/static/media/themify.e23a7dca.ttf) format('truetype'),
		url(/static/media/themify.9c8e96ec.svg#themify) format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="ti-"], [class*=" ti-"] {
	font-family: 'themify';
	speak: none;
	font-style: normal;
	font-weight: normal;
	-webkit-font-feature-settings: normal;
	        font-feature-settings: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.ti-wand:before {
	content: "\E600";
}
.ti-volume:before {
	content: "\E601";
}
.ti-user:before {
	content: "\E602";
}
.ti-unlock:before {
	content: "\E603";
}
.ti-unlink:before {
	content: "\E604";
}
.ti-trash:before {
	content: "\E605";
}
.ti-thought:before {
	content: "\E606";
}
.ti-target:before {
	content: "\E607";
}
.ti-tag:before {
	content: "\E608";
}
.ti-tablet:before {
	content: "\E609";
}
.ti-star:before {
	content: "\E60A";
}
.ti-spray:before {
	content: "\E60B";
}
.ti-signal:before {
	content: "\E60C";
}
.ti-shopping-cart:before {
	content: "\E60D";
}
.ti-shopping-cart-full:before {
	content: "\E60E";
}
.ti-settings:before {
	content: "\E60F";
}
.ti-search:before {
	content: "\E610";
}
.ti-zoom-in:before {
	content: "\E611";
}
.ti-zoom-out:before {
	content: "\E612";
}
.ti-cut:before {
	content: "\E613";
}
.ti-ruler:before {
	content: "\E614";
}
.ti-ruler-pencil:before {
	content: "\E615";
}
.ti-ruler-alt:before {
	content: "\E616";
}
.ti-bookmark:before {
	content: "\E617";
}
.ti-bookmark-alt:before {
	content: "\E618";
}
.ti-reload:before {
	content: "\E619";
}
.ti-plus:before {
	content: "\E61A";
}
.ti-pin:before {
	content: "\E61B";
}
.ti-pencil:before {
	content: "\E61C";
}
.ti-pencil-alt:before {
	content: "\E61D";
}
.ti-paint-roller:before {
	content: "\E61E";
}
.ti-paint-bucket:before {
	content: "\E61F";
}
.ti-na:before {
	content: "\E620";
}
.ti-mobile:before {
	content: "\E621";
}
.ti-minus:before {
	content: "\E622";
}
.ti-medall:before {
	content: "\E623";
}
.ti-medall-alt:before {
	content: "\E624";
}
.ti-marker:before {
	content: "\E625";
}
.ti-marker-alt:before {
	content: "\E626";
}
.ti-arrow-up:before {
	content: "\E627";
}
.ti-arrow-right:before {
	content: "\E628";
}
.ti-arrow-left:before {
	content: "\E629";
}
.ti-arrow-down:before {
	content: "\E62A";
}
.ti-lock:before {
	content: "\E62B";
}
.ti-location-arrow:before {
	content: "\E62C";
}
.ti-link:before {
	content: "\E62D";
}
.ti-layout:before {
	content: "\E62E";
}
.ti-layers:before {
	content: "\E62F";
}
.ti-layers-alt:before {
	content: "\E630";
}
.ti-key:before {
	content: "\E631";
}
.ti-import:before {
	content: "\E632";
}
.ti-image:before {
	content: "\E633";
}
.ti-heart:before {
	content: "\E634";
}
.ti-heart-broken:before {
	content: "\E635";
}
.ti-hand-stop:before {
	content: "\E636";
}
.ti-hand-open:before {
	content: "\E637";
}
.ti-hand-drag:before {
	content: "\E638";
}
.ti-folder:before {
	content: "\E639";
}
.ti-flag:before {
	content: "\E63A";
}
.ti-flag-alt:before {
	content: "\E63B";
}
.ti-flag-alt-2:before {
	content: "\E63C";
}
.ti-eye:before {
	content: "\E63D";
}
.ti-export:before {
	content: "\E63E";
}
.ti-exchange-vertical:before {
	content: "\E63F";
}
.ti-desktop:before {
	content: "\E640";
}
.ti-cup:before {
	content: "\E641";
}
.ti-crown:before {
	content: "\E642";
}
.ti-comments:before {
	content: "\E643";
}
.ti-comment:before {
	content: "\E644";
}
.ti-comment-alt:before {
	content: "\E645";
}
.ti-close:before {
	content: "\E646";
}
.ti-clip:before {
	content: "\E647";
}
.ti-angle-up:before {
	content: "\E648";
}
.ti-angle-right:before {
	content: "\E649";
}
.ti-angle-left:before {
	content: "\E64A";
}
.ti-angle-down:before {
	content: "\E64B";
}
.ti-check:before {
	content: "\E64C";
}
.ti-check-box:before {
	content: "\E64D";
}
.ti-camera:before {
	content: "\E64E";
}
.ti-announcement:before {
	content: "\E64F";
}
.ti-brush:before {
	content: "\E650";
}
.ti-briefcase:before {
	content: "\E651";
}
.ti-bolt:before {
	content: "\E652";
}
.ti-bolt-alt:before {
	content: "\E653";
}
.ti-blackboard:before {
	content: "\E654";
}
.ti-bag:before {
	content: "\E655";
}
.ti-move:before {
	content: "\E656";
}
.ti-arrows-vertical:before {
	content: "\E657";
}
.ti-arrows-horizontal:before {
	content: "\E658";
}
.ti-fullscreen:before {
	content: "\E659";
}
.ti-arrow-top-right:before {
	content: "\E65A";
}
.ti-arrow-top-left:before {
	content: "\E65B";
}
.ti-arrow-circle-up:before {
	content: "\E65C";
}
.ti-arrow-circle-right:before {
	content: "\E65D";
}
.ti-arrow-circle-left:before {
	content: "\E65E";
}
.ti-arrow-circle-down:before {
	content: "\E65F";
}
.ti-angle-double-up:before {
	content: "\E660";
}
.ti-angle-double-right:before {
	content: "\E661";
}
.ti-angle-double-left:before {
	content: "\E662";
}
.ti-angle-double-down:before {
	content: "\E663";
}
.ti-zip:before {
	content: "\E664";
}
.ti-world:before {
	content: "\E665";
}
.ti-wheelchair:before {
	content: "\E666";
}
.ti-view-list:before {
	content: "\E667";
}
.ti-view-list-alt:before {
	content: "\E668";
}
.ti-view-grid:before {
	content: "\E669";
}
.ti-uppercase:before {
	content: "\E66A";
}
.ti-upload:before {
	content: "\E66B";
}
.ti-underline:before {
	content: "\E66C";
}
.ti-truck:before {
	content: "\E66D";
}
.ti-timer:before {
	content: "\E66E";
}
.ti-ticket:before {
	content: "\E66F";
}
.ti-thumb-up:before {
	content: "\E670";
}
.ti-thumb-down:before {
	content: "\E671";
}
.ti-text:before {
	content: "\E672";
}
.ti-stats-up:before {
	content: "\E673";
}
.ti-stats-down:before {
	content: "\E674";
}
.ti-split-v:before {
	content: "\E675";
}
.ti-split-h:before {
	content: "\E676";
}
.ti-smallcap:before {
	content: "\E677";
}
.ti-shine:before {
	content: "\E678";
}
.ti-shift-right:before {
	content: "\E679";
}
.ti-shift-left:before {
	content: "\E67A";
}
.ti-shield:before {
	content: "\E67B";
}
.ti-notepad:before {
	content: "\E67C";
}
.ti-server:before {
	content: "\E67D";
}
.ti-quote-right:before {
	content: "\E67E";
}
.ti-quote-left:before {
	content: "\E67F";
}
.ti-pulse:before {
	content: "\E680";
}
.ti-printer:before {
	content: "\E681";
}
.ti-power-off:before {
	content: "\E682";
}
.ti-plug:before {
	content: "\E683";
}
.ti-pie-chart:before {
	content: "\E684";
}
.ti-paragraph:before {
	content: "\E685";
}
.ti-panel:before {
	content: "\E686";
}
.ti-package:before {
	content: "\E687";
}
.ti-music:before {
	content: "\E688";
}
.ti-music-alt:before {
	content: "\E689";
}
.ti-mouse:before {
	content: "\E68A";
}
.ti-mouse-alt:before {
	content: "\E68B";
}
.ti-money:before {
	content: "\E68C";
}
.ti-microphone:before {
	content: "\E68D";
}
.ti-menu:before {
	content: "\E68E";
}
.ti-menu-alt:before {
	content: "\E68F";
}
.ti-map:before {
	content: "\E690";
}
.ti-map-alt:before {
	content: "\E691";
}
.ti-loop:before {
	content: "\E692";
}
.ti-location-pin:before {
	content: "\E693";
}
.ti-list:before {
	content: "\E694";
}
.ti-light-bulb:before {
	content: "\E695";
}
.ti-Italic:before {
	content: "\E696";
}
.ti-info:before {
	content: "\E697";
}
.ti-infinite:before {
	content: "\E698";
}
.ti-id-badge:before {
	content: "\E699";
}
.ti-hummer:before {
	content: "\E69A";
}
.ti-home:before {
	content: "\E69B";
}
.ti-help:before {
	content: "\E69C";
}
.ti-headphone:before {
	content: "\E69D";
}
.ti-harddrives:before {
	content: "\E69E";
}
.ti-harddrive:before {
	content: "\E69F";
}
.ti-gift:before {
	content: "\E6A0";
}
.ti-game:before {
	content: "\E6A1";
}
.ti-filter:before {
	content: "\E6A2";
}
.ti-files:before {
	content: "\E6A3";
}
.ti-file:before {
	content: "\E6A4";
}
.ti-eraser:before {
	content: "\E6A5";
}
.ti-envelope:before {
	content: "\E6A6";
}
.ti-download:before {
	content: "\E6A7";
}
.ti-direction:before {
	content: "\E6A8";
}
.ti-direction-alt:before {
	content: "\E6A9";
}
.ti-dashboard:before {
	content: "\E6AA";
}
.ti-control-stop:before {
	content: "\E6AB";
}
.ti-control-shuffle:before {
	content: "\E6AC";
}
.ti-control-play:before {
	content: "\E6AD";
}
.ti-control-pause:before {
	content: "\E6AE";
}
.ti-control-forward:before {
	content: "\E6AF";
}
.ti-control-backward:before {
	content: "\E6B0";
}
.ti-cloud:before {
	content: "\E6B1";
}
.ti-cloud-up:before {
	content: "\E6B2";
}
.ti-cloud-down:before {
	content: "\E6B3";
}
.ti-clipboard:before {
	content: "\E6B4";
}
.ti-car:before {
	content: "\E6B5";
}
.ti-calendar:before {
	content: "\E6B6";
}
.ti-book:before {
	content: "\E6B7";
}
.ti-bell:before {
	content: "\E6B8";
}
.ti-basketball:before {
	content: "\E6B9";
}
.ti-bar-chart:before {
	content: "\E6BA";
}
.ti-bar-chart-alt:before {
	content: "\E6BB";
}
.ti-back-right:before {
	content: "\E6BC";
}
.ti-back-left:before {
	content: "\E6BD";
}
.ti-arrows-corner:before {
	content: "\E6BE";
}
.ti-archive:before {
	content: "\E6BF";
}
.ti-anchor:before {
	content: "\E6C0";
}
.ti-align-right:before {
	content: "\E6C1";
}
.ti-align-left:before {
	content: "\E6C2";
}
.ti-align-justify:before {
	content: "\E6C3";
}
.ti-align-center:before {
	content: "\E6C4";
}
.ti-alert:before {
	content: "\E6C5";
}
.ti-alarm-clock:before {
	content: "\E6C6";
}
.ti-agenda:before {
	content: "\E6C7";
}
.ti-write:before {
	content: "\E6C8";
}
.ti-window:before {
	content: "\E6C9";
}
.ti-widgetized:before {
	content: "\E6CA";
}
.ti-widget:before {
	content: "\E6CB";
}
.ti-widget-alt:before {
	content: "\E6CC";
}
.ti-wallet:before {
	content: "\E6CD";
}
.ti-video-clapper:before {
	content: "\E6CE";
}
.ti-video-camera:before {
	content: "\E6CF";
}
.ti-vector:before {
	content: "\E6D0";
}
.ti-themify-logo:before {
	content: "\E6D1";
}
.ti-themify-favicon:before {
	content: "\E6D2";
}
.ti-themify-favicon-alt:before {
	content: "\E6D3";
}
.ti-support:before {
	content: "\E6D4";
}
.ti-stamp:before {
	content: "\E6D5";
}
.ti-split-v-alt:before {
	content: "\E6D6";
}
.ti-slice:before {
	content: "\E6D7";
}
.ti-shortcode:before {
	content: "\E6D8";
}
.ti-shift-right-alt:before {
	content: "\E6D9";
}
.ti-shift-left-alt:before {
	content: "\E6DA";
}
.ti-ruler-alt-2:before {
	content: "\E6DB";
}
.ti-receipt:before {
	content: "\E6DC";
}
.ti-pin2:before {
	content: "\E6DD";
}
.ti-pin-alt:before {
	content: "\E6DE";
}
.ti-pencil-alt2:before {
	content: "\E6DF";
}
.ti-palette:before {
	content: "\E6E0";
}
.ti-more:before {
	content: "\E6E1";
}
.ti-more-alt:before {
	content: "\E6E2";
}
.ti-microphone-alt:before {
	content: "\E6E3";
}
.ti-magnet:before {
	content: "\E6E4";
}
.ti-line-double:before {
	content: "\E6E5";
}
.ti-line-dotted:before {
	content: "\E6E6";
}
.ti-line-dashed:before {
	content: "\E6E7";
}
.ti-layout-width-full:before {
	content: "\E6E8";
}
.ti-layout-width-default:before {
	content: "\E6E9";
}
.ti-layout-width-default-alt:before {
	content: "\E6EA";
}
.ti-layout-tab:before {
	content: "\E6EB";
}
.ti-layout-tab-window:before {
	content: "\E6EC";
}
.ti-layout-tab-v:before {
	content: "\E6ED";
}
.ti-layout-tab-min:before {
	content: "\E6EE";
}
.ti-layout-slider:before {
	content: "\E6EF";
}
.ti-layout-slider-alt:before {
	content: "\E6F0";
}
.ti-layout-sidebar-right:before {
	content: "\E6F1";
}
.ti-layout-sidebar-none:before {
	content: "\E6F2";
}
.ti-layout-sidebar-left:before {
	content: "\E6F3";
}
.ti-layout-placeholder:before {
	content: "\E6F4";
}
.ti-layout-menu:before {
	content: "\E6F5";
}
.ti-layout-menu-v:before {
	content: "\E6F6";
}
.ti-layout-menu-separated:before {
	content: "\E6F7";
}
.ti-layout-menu-full:before {
	content: "\E6F8";
}
.ti-layout-media-right-alt:before {
	content: "\E6F9";
}
.ti-layout-media-right:before {
	content: "\E6FA";
}
.ti-layout-media-overlay:before {
	content: "\E6FB";
}
.ti-layout-media-overlay-alt:before {
	content: "\E6FC";
}
.ti-layout-media-overlay-alt-2:before {
	content: "\E6FD";
}
.ti-layout-media-left-alt:before {
	content: "\E6FE";
}
.ti-layout-media-left:before {
	content: "\E6FF";
}
.ti-layout-media-center-alt:before {
	content: "\E700";
}
.ti-layout-media-center:before {
	content: "\E701";
}
.ti-layout-list-thumb:before {
	content: "\E702";
}
.ti-layout-list-thumb-alt:before {
	content: "\E703";
}
.ti-layout-list-post:before {
	content: "\E704";
}
.ti-layout-list-large-image:before {
	content: "\E705";
}
.ti-layout-line-solid:before {
	content: "\E706";
}
.ti-layout-grid4:before {
	content: "\E707";
}
.ti-layout-grid3:before {
	content: "\E708";
}
.ti-layout-grid2:before {
	content: "\E709";
}
.ti-layout-grid2-thumb:before {
	content: "\E70A";
}
.ti-layout-cta-right:before {
	content: "\E70B";
}
.ti-layout-cta-left:before {
	content: "\E70C";
}
.ti-layout-cta-center:before {
	content: "\E70D";
}
.ti-layout-cta-btn-right:before {
	content: "\E70E";
}
.ti-layout-cta-btn-left:before {
	content: "\E70F";
}
.ti-layout-column4:before {
	content: "\E710";
}
.ti-layout-column3:before {
	content: "\E711";
}
.ti-layout-column2:before {
	content: "\E712";
}
.ti-layout-accordion-separated:before {
	content: "\E713";
}
.ti-layout-accordion-merged:before {
	content: "\E714";
}
.ti-layout-accordion-list:before {
	content: "\E715";
}
.ti-ink-pen:before {
	content: "\E716";
}
.ti-info-alt:before {
	content: "\E717";
}
.ti-help-alt:before {
	content: "\E718";
}
.ti-headphone-alt:before {
	content: "\E719";
}
.ti-hand-point-up:before {
	content: "\E71A";
}
.ti-hand-point-right:before {
	content: "\E71B";
}
.ti-hand-point-left:before {
	content: "\E71C";
}
.ti-hand-point-down:before {
	content: "\E71D";
}
.ti-gallery:before {
	content: "\E71E";
}
.ti-face-smile:before {
	content: "\E71F";
}
.ti-face-sad:before {
	content: "\E720";
}
.ti-credit-card:before {
	content: "\E721";
}
.ti-control-skip-forward:before {
	content: "\E722";
}
.ti-control-skip-backward:before {
	content: "\E723";
}
.ti-control-record:before {
	content: "\E724";
}
.ti-control-eject:before {
	content: "\E725";
}
.ti-comments-smiley:before {
	content: "\E726";
}
.ti-brush-alt:before {
	content: "\E727";
}
.ti-youtube:before {
	content: "\E728";
}
.ti-vimeo:before {
	content: "\E729";
}
.ti-twitter:before {
	content: "\E72A";
}
.ti-time:before {
	content: "\E72B";
}
.ti-tumblr:before {
	content: "\E72C";
}
.ti-skype:before {
	content: "\E72D";
}
.ti-share:before {
	content: "\E72E";
}
.ti-share-alt:before {
	content: "\E72F";
}
.ti-rocket:before {
	content: "\E730";
}
.ti-pinterest:before {
	content: "\E731";
}
.ti-new-window:before {
	content: "\E732";
}
.ti-microsoft:before {
	content: "\E733";
}
.ti-list-ol:before {
	content: "\E734";
}
.ti-linkedin:before {
	content: "\E735";
}
.ti-layout-sidebar-2:before {
	content: "\E736";
}
.ti-layout-grid4-alt:before {
	content: "\E737";
}
.ti-layout-grid3-alt:before {
	content: "\E738";
}
.ti-layout-grid2-alt:before {
	content: "\E739";
}
.ti-layout-column4-alt:before {
	content: "\E73A";
}
.ti-layout-column3-alt:before {
	content: "\E73B";
}
.ti-layout-column2-alt:before {
	content: "\E73C";
}
.ti-instagram:before {
	content: "\E73D";
}
.ti-google:before {
	content: "\E73E";
}
.ti-github:before {
	content: "\E73F";
}
.ti-flickr:before {
	content: "\E740";
}
.ti-facebook:before {
	content: "\E741";
}
.ti-dropbox:before {
	content: "\E742";
}
.ti-dribbble:before {
	content: "\E743";
}
.ti-apple:before {
	content: "\E744";
}
.ti-android:before {
	content: "\E745";
}
.ti-save:before {
	content: "\E746";
}
.ti-save-alt:before {
	content: "\E747";
}
.ti-yahoo:before {
	content: "\E748";
}
.ti-wordpress:before {
	content: "\E749";
}
.ti-vimeo-alt:before {
	content: "\E74A";
}
.ti-twitter-alt:before {
	content: "\E74B";
}
.ti-tumblr-alt:before {
	content: "\E74C";
}
.ti-trello:before {
	content: "\E74D";
}
.ti-stack-overflow:before {
	content: "\E74E";
}
.ti-soundcloud:before {
	content: "\E74F";
}
.ti-sharethis:before {
	content: "\E750";
}
.ti-sharethis-alt:before {
	content: "\E751";
}
.ti-reddit:before {
	content: "\E752";
}
.ti-pinterest-alt:before {
	content: "\E753";
}
.ti-microsoft-alt:before {
	content: "\E754";
}
.ti-linux:before {
	content: "\E755";
}
.ti-jsfiddle:before {
	content: "\E756";
}
.ti-joomla:before {
	content: "\E757";
}
.ti-html5:before {
	content: "\E758";
}
.ti-flickr-alt:before {
	content: "\E759";
}
.ti-email:before {
	content: "\E75A";
}
.ti-drupal:before {
	content: "\E75B";
}
.ti-dropbox-alt:before {
	content: "\E75C";
}
.ti-css3:before {
	content: "\E75D";
}
.ti-rss:before {
	content: "\E75E";
}
.ti-rss-alt:before {
	content: "\E75F";
}

html, html a, body {
  -webkit-font-smoothing: antialiased; }

a {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

body {
  font-family: Roboto, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  color: #303238;
  line-height: 1.5;
  letter-spacing: 0.2px;
  overflow-x: hidden; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Roboto, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
  letter-spacing: 0.5px;
  line-height: 1.5; }
  h1 a,
  h2 a,
  h3 a,
  h4 a,
  h5 a,
  h6 a {
    font-family: Roboto, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif; }
  h1 small,
  h2 small,
  h3 small,
  h4 small,
  h5 small,
  h6 small {
    font-weight: 300;
    color: #3d4142; }

p {
  font-family: Roboto, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
  line-height: 1.9; }

.lead {
  font-size: 18px; }

ul {
  margin-bottom: 0;
  list-style: none; }

a {
  color: #0f9aee; }
  a:hover, a:focus {
    text-decoration: none;
    color: #0c7bbe; }
  a:focus {
    outline: none; }
  a.text-gray:hover, a.text-gray:focus, a.text-gray.active {
    color: #313435 !important; }

:focus {
  outline: none; }

hr {
  border-top: 1px solid rgba(0, 0, 0, 0.0625); }

.form-control:focus {
  box-shadow: none; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f9fafb; }

.table td {
  vertical-align: middle; }

.LabelFilter {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: baseline;
          align-items: baseline;
  font-size: .875rem;
  font-weight: 500; }
  .LabelFilter ul.FilterItems > li {
    border: solid 1px rgba(0, 0, 0, 0.0625);
    display: inline-block;
    cursor: pointer;
    -webkit-transition: all .3s ease;
    transition: all .3s ease; }
    .LabelFilter ul.FilterItems > li:first-child {
      border-radius: 4px 0 0 4px; }
    .LabelFilter ul.FilterItems > li:last-child {
      border-radius: 0 4px 4px 0; }
    .LabelFilter ul.FilterItems > li:not(:first-child) {
      margin-left: -1px; }
    .LabelFilter ul.FilterItems > li.active {
      border-color: #0f9d58;
      color: #0f9d58;
      position: relative;
      z-index: 2; }

.TableNav {
  border-top: 1px solid #dee2e6;
  padding-top: 15px; }
  .TableNav .Pagination {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end; }
    .TableNav .Pagination .page-item {
      display: inline-block;
      margin-right: 10px;
      -webkit-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out; }
      .TableNav .Pagination .page-item.active .page-link {
        color: #fff !important;
        background: #0f9d58; }
        .TableNav .Pagination .page-item.active .page-link:hover {
          color: #fff !important;
          background: #0f9d58; }
      .TableNav .Pagination .page-item.disabled {
        pointer-events: none;
        opacity: .4; }
    .TableNav .Pagination .page-link {
      border: none;
      color: #303238 !important;
      padding: 6px 12px;
      border-radius: 2px;
      cursor: pointer;
      text-decoration: none;
      display: inline-block; }
      .TableNav .Pagination .page-link:hover {
        color: #fff !important;
        background: #0f9d58; }

.PageTitle {
  font-size: 1.75rem;
  font-weight: 500; }

.spin {
  display: inline-block;
  margin-left: 10px;
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid #3498db;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 1s linear infinite;
  /* Safari */
  animation: spin 1s linear infinite; }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.FulfillmentStatus.unfulfilled .Text {
  background-color: #ffea8a;
  color: #212b36; }

.FulfillmentStatus .Text {
  font-weight: 400;
  background-color: #dfe3e8;
  color: #4c5c6c; }

.OrderPaymentStatusColumn .Text {
  font-weight: 400;
  background-color: #dfe3e8;
  color: #4c5c6c; }

.OrderTagsColumn .ListTags .TagItem {
  margin-left: 0.2rem; }
  .OrderTagsColumn .ListTags .TagItem:first-child {
    margin-left: 0; }

.OrderTableRow {
  -webkit-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
  cursor: pointer; }
  .OrderTableRow:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.01); }
  .OrderTableRow:hover {
    background-color: #f9fafb; }
  .OrderTableRow.fulfillment-fulfilled td {
    color: #637381; }
    .OrderTableRow.fulfillment-fulfilled td a {
      color: #637381; }

.OrdersTable .orders-table {
  table-layout: fixed;
  word-wrap: break-word; }
  .OrdersTable .orders-table .column-0 {
    width: 0%; }
  .OrdersTable .orders-table .column-1 {
    width: 8.33333%; }
  .OrdersTable .orders-table .column-2 {
    width: 16.66667%; }
  .OrdersTable .orders-table .column-3 {
    width: 25%; }
  .OrdersTable .orders-table .column-4 {
    width: 33.33333%; }
  .OrdersTable .orders-table .column-5 {
    width: 41.66667%; }
  .OrdersTable .orders-table .column-6 {
    width: 50%; }
  .OrdersTable .orders-table .column-7 {
    width: 58.33333%; }
  .OrdersTable .orders-table .column-8 {
    width: 66.66667%; }
  .OrdersTable .orders-table .column-9 {
    width: 75%; }
  .OrdersTable .orders-table .column-10 {
    width: 83.33333%; }
  .OrdersTable .orders-table .column-11 {
    width: 91.66667%; }
  .OrdersTable .orders-table .column-12 {
    width: 100%; }

.OrdersTable .product-item, .OrdersTable .product-item:hover {
  color: unset; }

.OrdersTable .Table {
  margin-top: 20px; }

.OrdersTable .TotalItems {
  float: right;
  font-weight: 700;
  color: #313435; }

.Table {
  border-collapse: collapse;
  border-radius: 6px 6px 0 0; }

.TableHead > tr > th {
  background: #fafafa;
  color: #535353;
  border-bottom: 1px solid #e8e8e8; }

.OrderAddress table {
  border: none;
  border-collapse: collapse;
  background-color: transparent; }
  .OrderAddress table tr, .OrderAddress table td {
    border: none; }

.img-popover {
  max-width: 340px;
  height: auto;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 2px; }

.StatisticItem .card-title {
  margin: 15px; }

.StatisticItem.card {
  border-color: rgba(0, 0, 0, 0.0625);
  background-color: #f2f3f5; }

.StatisticOrderItem {
  color: #fff; }
  .StatisticOrderItem .card-title {
    margin: 15px; }

.StatisticsContainer .StatisticsOrder {
  margin-bottom: 20px; }

.StatisticsContainer .Statistics {
  margin-top: 20px; }

.StatisticsFilter label, .StatisticsFilter ul {
  margin: auto 0; }

.StatisticsFilter .nav-link {
  cursor: pointer; }

.OrderContainer {
  margin-top: 20px; }
  .OrderContainer h3 {
    color: gray; }

.OrderHistories {
  margin-top: 20px; }
  .OrderHistories .card {
    margin-bottom: 10px; }

.OrderNotes {
  margin-top: 20px; }

.OrderItemsUnfulfilled {
  margin-top: 20px; }
  .OrderItemsUnfulfilled .Item {
    margin: 0px; }
    .OrderItemsUnfulfilled .Item a {
      display: -webkit-flex;
      display: flex; }
    .OrderItemsUnfulfilled .Item .ItemBody {
      margin: 0px;
      border: none; }
  .OrderItemsUnfulfilled .img-popover {
    position: absolute;
    z-index: 100;
    max-height: 500px;
    max-width: 500px; }

.OrderBuyerDetails {
  margin-top: 20px; }
  .OrderBuyerDetails .cursor-pointer {
    cursor: pointer; }

.PaymentPending {
  margin-top: 20px; }

.OrderFulfilled {
  margin-top: 20px; }

.OrderTags {
  margin-top: 20px; }
  .OrderTags .ListTags {
    padding: 0px; }
    .OrderTags .ListTags .Tag {
      background: #dfe3e8;
      border-radius: 10px;
      padding: 4px 10px 4px 10px;
      font-size: 15px;
      list-style: none;
      display: inline-block;
      margin-top: 5px; }
      .OrderTags .ListTags .Tag .Close {
        margin-left: 10px;
        font-size: 16px;
        cursor: pointer; }

.OrderFulfillmentContainer {
  margin-top: 20px; }
  .OrderFulfillmentContainer .Fulfillment .Summary {
    margin-top: 20px; }
  .OrderFulfillmentContainer .MarkAsFulfilled {
    margin-top: 20px; }
    .OrderFulfillmentContainer .MarkAsFulfilled .cursor-pointer {
      cursor: pointer; }
    .OrderFulfillmentContainer .MarkAsFulfilled .NumericInput {
      width: 80px;
      height: 100%;
      text-align: center; }
  .OrderFulfillmentContainer .QuantityFulfill tr {
    border-bottom: 1px solid #eee; }
  .OrderFulfillmentContainer .QuantityFulfill .ItemInfo {
    margin: auto; }

.NavbarTop {
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.0625);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  height: 65px;
  margin-bottom: 0;
  position: fixed;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  width: calc(100% - 280px);
  z-index: 800;
  padding: 0 15px; }
  .NavbarTop .SideBarToggle {
    cursor: pointer; }
    .NavbarTop .SideBarToggle i {
      font-size: 17px; }
  .NavbarTop .LogoLink img {
    height: 40px;
    width: auto; }
  .NavbarTop .LogoLink .SiteName {
    display: inline-block;
    color: #303238;
    font-size: 1.625rem;
    margin-top: -6px; }
  .NavbarTop > ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: -webkit-inline-flex;
    display: inline-flex; }
    .NavbarTop > ul > li {
      display: -webkit-inline-flex;
      display: inline-flex;
      -webkit-align-items: center;
              align-items: center;
      padding: 0 15px; }
  .NavbarTop .LogoLink {
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
            align-items: center; }

.NavbarLeft {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 280px;
  overflow: hidden;
  background-color: #fff;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  z-index: 1000;
  border-right: solid 1px rgba(0, 0, 0, 0.0625); }
  .NavbarLeft .nav-link {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: baseline;
            align-items: baseline;
    font-size: 1rem;
    font-weight: 500;
    padding: 5px 15px;
    position: relative;
    white-space: nowrap;
    color: #303238;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease; }
    .NavbarLeft .nav-link:hover, .NavbarLeft .nav-link.active {
      color: #0f9d58;
      text-decoration: none; }
  .NavbarLeft .IconHolder {
    border-radius: 6px;
    display: inline-block;
    font-size: 17px;
    height: 35px;
    left: 0;
    line-height: 35px;
    position: relative;
    text-align: center;
    width: 35px; }
  .NavbarLeft .NavLogo {
    border-bottom: solid 1px rgba(0, 0, 0, 0.0625); }
    .NavbarLeft .NavLogo .SiteName {
      color: #313435;
      font-weight: 500;
      margin-left: 5px;
      font-size: 1.09375rem;
      vertical-align: middle; }
    .NavbarLeft .NavLogo .LinkLogo {
      text-decoration: none;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center; }
    .NavbarLeft .NavLogo .LogoImage {
      -webkit-flex: 0 0 66px;
              flex: 0 0 66px; }
      .NavbarLeft .NavLogo .LogoImage img {
        width: 100%;
        padding: 10px; }
  .NavbarLeft .PrimaryMenu {
    margin-top: 10px; }
    .NavbarLeft .PrimaryMenu .Title {
      display: inline-block;
      margin-left: 14px; }

.DashboardContainer .DashboardInner {
  min-height: 100vh;
  padding-left: 280px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease; }

.DashboardContainer .MainContentInner .SectionInner {
  background-color: #fff;
  padding: 30px 15px;
  border: solid 1px rgba(0, 0, 0, 0.0625);
  border-radius: 4px; }

.DashboardContainer .MainContent {
  padding: 85px 20px 60px;
  min-height: 100vh;
  background-color: #f9fafb; }
  @media screen and (max-width: 991px) {
    .DashboardContainer .MainContent {
      padding: 85px 5px 5px; } }

.DashboardContainer .nav-link .Label {
  color: #fff; }

.DashboardContainer.isCollapsed .nav-link .Label {
  display: none; }

.DashboardContainer.isCollapsed .NavbarLeft {
  width: 70px; }
  .DashboardContainer.isCollapsed .NavbarLeft .Title {
    display: none; }

.DashboardContainer.isCollapsed .DashboardInner {
  padding-left: 70px; }

.DashboardContainer.isCollapsed .NavbarTop {
  width: calc(100% - 70px); }

.NavbarLeft .NavbarLeftInner {
  -webkit-flex-direction: column;
          flex-direction: column;
  min-height: 100%; }
  .NavbarLeft .NavbarLeftInner .BottomMenu {
    margin-bottom: 10px; }

.SettingsContainer .Card {
  padding: 0; }
  .SettingsContainer .Card .SettingsWrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap; }
    .SettingsContainer .Card .SettingsWrapper .SettingWrapper {
      cursor: pointer;
      padding: 10px;
      width: calc(100% / 3);
      display: -webkit-flex;
      display: flex;
      -webkit-flex-wrap: nowrap;
              flex-wrap: nowrap; }
      .SettingsContainer .Card .SettingsWrapper .SettingWrapper .IconWrapper {
        height: 2.5rem;
        width: 2.5rem;
        background: #f4f6f8;
        color: #919eab;
        display: -webkit-inline-flex;
        display: inline-flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center;
        border-radius: 4px; }
      .SettingsContainer .Card .SettingsWrapper .SettingWrapper .Info {
        padding: 0 1rem;
        margin-top: -6px; }
        .SettingsContainer .Card .SettingsWrapper .SettingWrapper .Info p {
          padding: 0;
          margin: 0; }
        .SettingsContainer .Card .SettingsWrapper .SettingWrapper .Info .Title {
          color: #007ace;
          margin: 0; }
      .SettingsContainer .Card .SettingsWrapper .SettingWrapper:hover {
        background: #f9fafb; }
        .SettingsContainer .Card .SettingsWrapper .SettingWrapper:hover .IconWrapper {
          background: #dfe3e8; }
        .SettingsContainer .Card .SettingsWrapper .SettingWrapper:hover .Info .Title {
          color: #084e8a; }

.SettingsContainer .ProductInfo .SettingInput label {
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  margin-right: 20px; }

#discount_value {
  max-width: 100px; }

.SettingContainer {
  margin: 20px 0; }
  .SettingContainer .BackWrapper .BackButton {
    cursor: pointer;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0; }
    .SettingContainer .BackWrapper .BackButton span {
      margin: 0 1rem; }
    .SettingContainer .BackWrapper .BackButton:hover {
      color: #007bff; }
  .SettingContainer .MenuTitle {
    font-weight: 600;
    font-size: 1.75rem;
    border-bottom: 1px solid #dfe3e8;
    padding-bottom: 0.5rem; }
  .SettingContainer .Section {
    display: inline-grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    width: 100%;
    border-bottom: 1px solid #ccc;
    padding: 1.5rem 0; }
    .SettingContainer .Section .SectionSetting {
      width: 100%;
      grid-column: 2/5;
      height: 100%; }
      .SettingContainer .Section .SectionSetting .Card {
        height: 100%; }
  .SettingContainer .ButtonSave {
    float: right; }
    .SettingContainer .ButtonSave button {
      margin: 1rem 0 2rem;
      min-width: 150px;
      display: -webkit-inline-flex;
      display: inline-flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-transition: all .3s;
      transition: all .3s; }
  .SettingContainer .BreakLine {
    border-bottom: 1px solid #ccc;
    margin: 1rem 0; }

.SectionDetails {
  width: 100%;
  grid-column: 1/2; }

.StoreCurrency .SectionHeader {
  display: block; }
  .StoreCurrency .SectionHeader label {
    float: left; }
  .StoreCurrency .SectionHeader span {
    float: right;
    color: #007bff;
    cursor: pointer; }

.SaveSuccessModal {
  float: left;
  margin-top: 1rem; }
  .SaveSuccessModal .Card {
    display: none;
    width: 100%;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .SaveSuccessModal .display {
    display: block; }

.SettingCheckbox {
  margin-bottom: 1rem; }

.ImportForm .BackWrapper .BackButton {
  cursor: pointer;
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0; }
  .ImportForm .BackWrapper .BackButton span {
    margin: 0 1rem; }
  .ImportForm .BackWrapper .BackButton:hover {
    color: #007bff; }

.ImportForm .SubTitle {
  font-size: .985rem;
  margin-bottom: 30px;
  margin-top: -10px; }

.StatusFilter {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: baseline;
          align-items: baseline; }
  .StatusFilter > label {
    margin-right: 40px; }

.TableLength {
  color: #313435;
  font-weight: 400; }
  .TableLength select {
    border: 1px solid rgba(0, 0, 0, 0.0625);
    border-radius: 2px;
    box-shadow: none;
    height: 40px;
    font-size: 14px;
    padding: 5px;
    margin-left: 5px;
    margin-right: 5px;
    color: #303238;
    -webkit-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in; }

.ProductActions .Icon {
  cursor: pointer; }

.ProductActions .Remove {
  padding: 2px 4px; }

.CollectionsColumn .ListCollections {
  padding: 0;
  margin: 0; }
  .CollectionsColumn .ListCollections li {
    margin-bottom: 5px;
    margin-right: 5px; }
    .CollectionsColumn .ListCollections li:last-child {
      margin-right: 0; }

.TagsColumn ul {
  -webkit-flex-direction: row;
          flex-direction: row; }
  .TagsColumn ul li {
    margin-bottom: 5px;
    margin-right: 5px; }
    .TagsColumn ul li:last-child {
      margin-right: 0; }

.TableImporterJobs table tr {
  min-width: 100%; }

.ImporterPageContainer .ButtonImportProduct {
  display: inline-block;
  margin-bottom: 20px;
  float: right; }
  .ImporterPageContainer .ButtonImportProduct i {
    margin-right: 10px; }

.ImporterPageContainer .TableMeta {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: baseline;
          align-items: baseline;
  margin: 30px 0 20px; }
  .ImporterPageContainer .TableMeta .TotalItems {
    font-weight: 700;
    color: #313435;
    -webkit-align-self: flex-end;
            align-self: flex-end; }

.ProductsContainer .TableNav .Previous span:first-child, .ProductsContainer .TableNav .Next span:first-child {
  display: none; }

.ProductsContainer .TableNav .Previous span.sr-only, .ProductsContainer .TableNav .Next span.sr-only {
  position: static;
  position: initial;
  width: auto;
  height: auto;
  clip: auto;
  clip: initial; }

.TopButtons {
  display: inline-block; }
  .TopButtons .LeftButtons {
    float: left; }
  .TopButtons .RightButtons {
    float: right;
    padding: 1rem 0; }

.FilterBar {
  margin: 10px 0; }
  .FilterBar .Select option {
    padding: .375rem 1.75rem .375rem .75rem; }

.pretty * {
  box-sizing: border-box; }

.pretty input:not([type='checkbox']):not([type='radio']) {
  display: none; }

.pretty {
  position: relative;
  display: inline-block;
  margin-right: 1em;
  white-space: nowrap;
  line-height: 1; }
  .pretty input {
    position: absolute;
    left: 0;
    top: 0;
    min-width: 1em;
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 0;
    margin: 0;
    padding: 0;
    cursor: pointer; }
  .pretty .state label {
    position: static;
    position: initial;
    display: inline-block;
    font-weight: normal;
    margin: 0;
    text-indent: 1.5em;
    min-width: calc(1em + 2px); }
    .pretty .state label:before, .pretty .state label:after {
      content: '';
      width: calc(1em + 2px);
      height: calc(1em + 2px);
      display: block;
      box-sizing: border-box;
      border-radius: 0;
      border: 1px solid transparent;
      z-index: 0;
      position: absolute;
      left: 0;
      top: calc((0% - (100% - 1em)) - 8%);
      background-color: transparent; }
    .pretty .state label:before {
      border-color: #bdc3c7; }
  .pretty .state.p-is-hover, .pretty .state.p-is-indeterminate {
    display: none; }

@-webkit-keyframes zoom {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0); } }

@keyframes zoom {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0); } }

@-webkit-keyframes tada {
  0% {
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
    -webkit-transform: scale(7);
            transform: scale(7); }
  38% {
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); }
  55% {
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    -webkit-transform: scale(1.5);
            transform: scale(1.5); }
  72% {
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    -webkit-transform: scale(1);
            transform: scale(1); }
  81% {
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    -webkit-transform: scale(1.24);
            transform: scale(1.24); }
  89% {
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    -webkit-transform: scale(1);
            transform: scale(1); }
  95% {
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    -webkit-transform: scale(1.04);
            transform: scale(1.04); }
  100% {
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes tada {
  0% {
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
    -webkit-transform: scale(7);
            transform: scale(7); }
  38% {
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); }
  55% {
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    -webkit-transform: scale(1.5);
            transform: scale(1.5); }
  72% {
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    -webkit-transform: scale(1);
            transform: scale(1); }
  81% {
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    -webkit-transform: scale(1.24);
            transform: scale(1.24); }
  89% {
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    -webkit-transform: scale(1);
            transform: scale(1); }
  95% {
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    -webkit-transform: scale(1.04);
            transform: scale(1.04); }
  100% {
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes jelly {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1); }
  30% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1); }
  40% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1); }
  50% {
    -webkit-transform: scale3d(0.85, 1.15, 1);
            transform: scale3d(0.85, 1.15, 1); }
  65% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1); }
  75% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1); }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1); } }

@keyframes jelly {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1); }
  30% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1); }
  40% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1); }
  50% {
    -webkit-transform: scale3d(0.85, 1.15, 1);
            transform: scale3d(0.85, 1.15, 1); }
  65% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1); }
  75% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1); }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1); } }

@-webkit-keyframes rotate {
  0% {
    opacity: 0;
    -webkit-transform: translateZ(-200px) rotate(-45deg);
            transform: translateZ(-200px) rotate(-45deg); }
  100% {
    opacity: 1;
    -webkit-transform: translateZ(0) rotate(0);
            transform: translateZ(0) rotate(0); } }

@keyframes rotate {
  0% {
    opacity: 0;
    -webkit-transform: translateZ(-200px) rotate(-45deg);
            transform: translateZ(-200px) rotate(-45deg); }
  100% {
    opacity: 1;
    -webkit-transform: translateZ(0) rotate(0);
            transform: translateZ(0) rotate(0); } }

@-webkit-keyframes pulse {
  0% {
    box-shadow: 0px 0px 0px 0px #bdc3c7; }
  100% {
    box-shadow: 0px 0px 0px 1.5em rgba(189, 195, 199, 0); } }

@keyframes pulse {
  0% {
    box-shadow: 0px 0px 0px 0px #bdc3c7; }
  100% {
    box-shadow: 0px 0px 0px 1.5em rgba(189, 195, 199, 0); } }

.pretty.p-default.p-fill .state label:after {
  -webkit-transform: scale(1);
          transform: scale(1); }

.pretty.p-default .state label:after {
  -webkit-transform: scale(0.6);
          transform: scale(0.6); }

.pretty.p-default input:checked ~ .state label:after {
  background-color: #bdc3c7 !important; }

.pretty.p-default.p-thick .state label:before, .pretty.p-default.p-thick .state label:after {
  border-width: calc(1em / 7); }

.pretty.p-default.p-thick .state label:after {
  -webkit-transform: scale(0.4) !important;
          transform: scale(0.4) !important; }

.pretty.p-icon .state .icon {
  position: absolute;
  font-size: 1em;
  width: calc(1em + 2px);
  height: calc(1em + 2px);
  left: 0;
  z-index: 1;
  text-align: center;
  line-height: normal;
  top: calc((0% - (100% - 1em)) - 8%);
  border: 1px solid transparent;
  opacity: 0; }

.pretty.p-icon .state .icon:before {
  margin: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  line-height: 1; }

.pretty.p-icon input:checked ~ .state .icon {
  opacity: 1; }

.pretty.p-icon input:checked ~ .state label:before {
  border-color: #5a656b; }

.pretty.p-svg .state .svg {
  position: absolute;
  font-size: 1em;
  width: calc(1em + 2px);
  height: calc(1em + 2px);
  left: 0;
  z-index: 1;
  text-align: center;
  line-height: normal;
  top: calc((0% - (100% - 1em)) - 8%);
  border: 1px solid transparent;
  opacity: 0; }

.pretty.p-svg .state svg {
  margin: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  line-height: 1; }

.pretty.p-svg input:checked ~ .state .svg {
  opacity: 1; }

.pretty.p-image .state img {
  opacity: 0;
  position: absolute;
  width: calc(1em + 2px);
  height: calc(1em + 2px);
  top: 0;
  top: calc((0% - (100% - 1em)) - 8%);
  left: 0;
  z-index: 0;
  text-align: center;
  line-height: normal;
  -webkit-transform: scale(0.8);
          transform: scale(0.8); }

.pretty.p-image input:checked ~ .state img {
  opacity: 1; }

.pretty.p-switch input {
  min-width: 2em; }

.pretty.p-switch .state {
  position: relative; }
  .pretty.p-switch .state:before {
    content: '';
    border: 1px solid #bdc3c7;
    border-radius: 60px;
    width: 2em;
    box-sizing: unset;
    height: calc(1em + 2px);
    position: absolute;
    top: 0;
    top: calc((0% - (100% - 1em)) - 16%);
    z-index: 0;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease; }
  .pretty.p-switch .state label {
    text-indent: 2.5em; }
    .pretty.p-switch .state label:before, .pretty.p-switch .state label:after {
      -webkit-transition: all 0.5s ease;
      transition: all 0.5s ease;
      border-radius: 100%;
      left: 0;
      border-color: transparent;
      -webkit-transform: scale(0.8);
              transform: scale(0.8); }
    .pretty.p-switch .state label:after {
      background-color: #bdc3c7 !important; }

.pretty.p-switch input:checked ~ .state:before {
  border-color: #5a656b; }

.pretty.p-switch input:checked ~ .state label:before {
  opacity: 0; }

.pretty.p-switch input:checked ~ .state label:after {
  background-color: #5a656b !important;
  left: 1em; }

.pretty.p-switch.p-fill input:checked ~ .state:before {
  border-color: #5a656b;
  background-color: #5a656b !important; }

.pretty.p-switch.p-fill input:checked ~ .state label:before {
  opacity: 0; }

.pretty.p-switch.p-fill input:checked ~ .state label:after {
  background-color: #fff !important;
  left: 1em; }

.pretty.p-switch.p-slim .state:before {
  height: 0.1em;
  background: #bdc3c7 !important;
  top: calc(50% - 0.1em); }

.pretty.p-switch.p-slim input:checked ~ .state:before {
  border-color: #5a656b;
  background-color: #5a656b !important; }

.pretty.p-has-hover input:hover ~ .state:not(.p-is-hover) {
  display: none; }

.pretty.p-has-hover input:hover ~ .state.p-is-hover {
  display: block; }
  .pretty.p-has-hover input:hover ~ .state.p-is-hover .icon {
    display: block; }

.pretty.p-has-focus input:focus ~ .state label:before {
  box-shadow: 0px 0px 3px 0px #bdc3c7; }

.pretty.p-has-indeterminate input[type='checkbox']:indeterminate ~ .state:not(.p-is-indeterminate) {
  display: none; }

.pretty.p-has-indeterminate input[type='checkbox']:indeterminate ~ .state.p-is-indeterminate {
  display: block; }
  .pretty.p-has-indeterminate input[type='checkbox']:indeterminate ~ .state.p-is-indeterminate .icon {
    display: block;
    opacity: 1; }

.pretty.p-toggle .state.p-on {
  opacity: 0;
  display: none; }

.pretty.p-toggle .state.p-off,
.pretty.p-toggle .state .icon,
.pretty.p-toggle .state .svg,
.pretty.p-toggle .state img {
  opacity: 1;
  display: inherit; }

.pretty.p-toggle .state.p-off .icon {
  color: #bdc3c7; }

.pretty.p-toggle input:checked ~ .state.p-on {
  opacity: 1;
  display: inherit; }

.pretty.p-toggle input:checked ~ .state.p-off {
  opacity: 0;
  display: none; }

.pretty.p-plain input:checked ~ .state label:before,
.pretty.p-plain.p-toggle .state label:before {
  content: none; }

.pretty.p-plain.p-plain .icon {
  -webkit-transform: scale(1.1);
          transform: scale(1.1); }

.pretty.p-round .state label:before, .pretty.p-round .state label:after {
  border-radius: 100%; }

.pretty.p-round.p-icon .state .icon {
  border-radius: 100%;
  overflow: hidden; }
  .pretty.p-round.p-icon .state .icon:before {
    -webkit-transform: scale(0.8);
            transform: scale(0.8); }

.pretty.p-curve .state label:before, .pretty.p-curve .state label:after {
  border-radius: 20%; }

.pretty.p-smooth label:before,
.pretty.p-smooth label:after,
.pretty.p-smooth .icon,
.pretty.p-smooth .svg {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease; }

.pretty.p-smooth input:checked + .state label:after {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease; }

.pretty.p-smooth input:checked + .state .icon,
.pretty.p-smooth input:checked + .state .svg,
.pretty.p-smooth input:checked + .state img {
  -webkit-animation: zoom 0.2s ease;
          animation: zoom 0.2s ease; }

.pretty.p-smooth.p-default input:checked + .state label:after {
  -webkit-animation: zoom 0.2s ease;
          animation: zoom 0.2s ease; }

.pretty.p-smooth.p-plain input:checked + .state label:before {
  content: '';
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease; }

.pretty.p-tada:not(.p-default) input:checked + .state .icon,
.pretty.p-tada:not(.p-default) input:checked + .state .svg,
.pretty.p-tada:not(.p-default) input:checked + .state img,
.pretty.p-tada:not(.p-default) input:checked + .state label:before,
.pretty.p-tada:not(.p-default) input:checked + .state label:after {
  -webkit-animation: tada 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1 alternate;
          animation: tada 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1 alternate;
  opacity: 1; }

.pretty.p-jelly:not(.p-default) input:checked + .state .icon,
.pretty.p-jelly:not(.p-default) input:checked + .state .svg,
.pretty.p-jelly:not(.p-default) input:checked + .state img,
.pretty.p-jelly:not(.p-default) input:checked + .state label:before,
.pretty.p-jelly:not(.p-default) input:checked + .state label:after {
  -webkit-animation: jelly 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
          animation: jelly 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 1; }

.pretty.p-jelly:not(.p-default) input:checked + .state label:before {
  border-color: transparent; }

.pretty.p-rotate:not(.p-default) input:checked ~ .state .icon,
.pretty.p-rotate:not(.p-default) input:checked ~ .state .svg,
.pretty.p-rotate:not(.p-default) input:checked ~ .state img,
.pretty.p-rotate:not(.p-default) input:checked ~ .state label:before,
.pretty.p-rotate:not(.p-default) input:checked ~ .state label:after {
  -webkit-animation: rotate 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
          animation: rotate 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 1; }

.pretty.p-rotate:not(.p-default) input:checked ~ .state label:before {
  border-color: transparent; }

.pretty.p-pulse:not(.p-switch) input:checked ~ .state label:before {
  -webkit-animation: pulse 1s;
          animation: pulse 1s; }

.pretty input[disabled] {
  cursor: not-allowed;
  display: none; }
  .pretty input[disabled] ~ * {
    opacity: .5; }

.pretty.p-locked input {
  display: none;
  cursor: not-allowed; }

.pretty input:checked ~ .state.p-primary label:after,
.pretty.p-toggle .state.p-primary label:after {
  background-color: #428bca !important; }

.pretty input:checked ~ .state.p-primary .icon,
.pretty input:checked ~ .state.p-primary .svg,
.pretty.p-toggle .state.p-primary .icon,
.pretty.p-toggle .state.p-primary .svg {
  color: #fff;
  stroke: #fff; }

.pretty input:checked ~ .state.p-primary-o label:before,
.pretty.p-toggle .state.p-primary-o label:before {
  border-color: #428bca; }

.pretty input:checked ~ .state.p-primary-o label:after,
.pretty.p-toggle .state.p-primary-o label:after {
  background-color: transparent; }

.pretty input:checked ~ .state.p-primary-o .icon,
.pretty input:checked ~ .state.p-primary-o .svg,
.pretty input:checked ~ .state.p-primary-o svg,
.pretty.p-toggle .state.p-primary-o .icon,
.pretty.p-toggle .state.p-primary-o .svg,
.pretty.p-toggle .state.p-primary-o svg {
  color: #428bca;
  stroke: #428bca; }

.pretty.p-default:not(.p-fill) input:checked ~ .state.p-primary-o label:after {
  background-color: #428bca !important; }

.pretty.p-switch input:checked ~ .state.p-primary:before {
  border-color: #428bca; }

.pretty.p-switch.p-fill input:checked ~ .state.p-primary:before {
  background-color: #428bca !important; }

.pretty.p-switch.p-slim input:checked ~ .state.p-primary:before {
  border-color: #245682;
  background-color: #245682 !important; }

.pretty input:checked ~ .state.p-info label:after,
.pretty.p-toggle .state.p-info label:after {
  background-color: #5bc0de !important; }

.pretty input:checked ~ .state.p-info .icon,
.pretty input:checked ~ .state.p-info .svg,
.pretty.p-toggle .state.p-info .icon,
.pretty.p-toggle .state.p-info .svg {
  color: #fff;
  stroke: #fff; }

.pretty input:checked ~ .state.p-info-o label:before,
.pretty.p-toggle .state.p-info-o label:before {
  border-color: #5bc0de; }

.pretty input:checked ~ .state.p-info-o label:after,
.pretty.p-toggle .state.p-info-o label:after {
  background-color: transparent; }

.pretty input:checked ~ .state.p-info-o .icon,
.pretty input:checked ~ .state.p-info-o .svg,
.pretty input:checked ~ .state.p-info-o svg,
.pretty.p-toggle .state.p-info-o .icon,
.pretty.p-toggle .state.p-info-o .svg,
.pretty.p-toggle .state.p-info-o svg {
  color: #5bc0de;
  stroke: #5bc0de; }

.pretty.p-default:not(.p-fill) input:checked ~ .state.p-info-o label:after {
  background-color: #5bc0de !important; }

.pretty.p-switch input:checked ~ .state.p-info:before {
  border-color: #5bc0de; }

.pretty.p-switch.p-fill input:checked ~ .state.p-info:before {
  background-color: #5bc0de !important; }

.pretty.p-switch.p-slim input:checked ~ .state.p-info:before {
  border-color: #2390b0;
  background-color: #2390b0 !important; }

.pretty input:checked ~ .state.p-success label:after,
.pretty.p-toggle .state.p-success label:after {
  background-color: #5cb85c !important; }

.pretty input:checked ~ .state.p-success .icon,
.pretty input:checked ~ .state.p-success .svg,
.pretty.p-toggle .state.p-success .icon,
.pretty.p-toggle .state.p-success .svg {
  color: #fff;
  stroke: #fff; }

.pretty input:checked ~ .state.p-success-o label:before,
.pretty.p-toggle .state.p-success-o label:before {
  border-color: #5cb85c; }

.pretty input:checked ~ .state.p-success-o label:after,
.pretty.p-toggle .state.p-success-o label:after {
  background-color: transparent; }

.pretty input:checked ~ .state.p-success-o .icon,
.pretty input:checked ~ .state.p-success-o .svg,
.pretty input:checked ~ .state.p-success-o svg,
.pretty.p-toggle .state.p-success-o .icon,
.pretty.p-toggle .state.p-success-o .svg,
.pretty.p-toggle .state.p-success-o svg {
  color: #5cb85c;
  stroke: #5cb85c; }

.pretty.p-default:not(.p-fill) input:checked ~ .state.p-success-o label:after {
  background-color: #5cb85c !important; }

.pretty.p-switch input:checked ~ .state.p-success:before {
  border-color: #5cb85c; }

.pretty.p-switch.p-fill input:checked ~ .state.p-success:before {
  background-color: #5cb85c !important; }

.pretty.p-switch.p-slim input:checked ~ .state.p-success:before {
  border-color: #357935;
  background-color: #357935 !important; }

.pretty input:checked ~ .state.p-warning label:after,
.pretty.p-toggle .state.p-warning label:after {
  background-color: #f0ad4e !important; }

.pretty input:checked ~ .state.p-warning .icon,
.pretty input:checked ~ .state.p-warning .svg,
.pretty.p-toggle .state.p-warning .icon,
.pretty.p-toggle .state.p-warning .svg {
  color: #fff;
  stroke: #fff; }

.pretty input:checked ~ .state.p-warning-o label:before,
.pretty.p-toggle .state.p-warning-o label:before {
  border-color: #f0ad4e; }

.pretty input:checked ~ .state.p-warning-o label:after,
.pretty.p-toggle .state.p-warning-o label:after {
  background-color: transparent; }

.pretty input:checked ~ .state.p-warning-o .icon,
.pretty input:checked ~ .state.p-warning-o .svg,
.pretty input:checked ~ .state.p-warning-o svg,
.pretty.p-toggle .state.p-warning-o .icon,
.pretty.p-toggle .state.p-warning-o .svg,
.pretty.p-toggle .state.p-warning-o svg {
  color: #f0ad4e;
  stroke: #f0ad4e; }

.pretty.p-default:not(.p-fill) input:checked ~ .state.p-warning-o label:after {
  background-color: #f0ad4e !important; }

.pretty.p-switch input:checked ~ .state.p-warning:before {
  border-color: #f0ad4e; }

.pretty.p-switch.p-fill input:checked ~ .state.p-warning:before {
  background-color: #f0ad4e !important; }

.pretty.p-switch.p-slim input:checked ~ .state.p-warning:before {
  border-color: #c77c11;
  background-color: #c77c11 !important; }

.pretty input:checked ~ .state.p-danger label:after,
.pretty.p-toggle .state.p-danger label:after {
  background-color: #d9534f !important; }

.pretty input:checked ~ .state.p-danger .icon,
.pretty input:checked ~ .state.p-danger .svg,
.pretty.p-toggle .state.p-danger .icon,
.pretty.p-toggle .state.p-danger .svg {
  color: #fff;
  stroke: #fff; }

.pretty input:checked ~ .state.p-danger-o label:before,
.pretty.p-toggle .state.p-danger-o label:before {
  border-color: #d9534f; }

.pretty input:checked ~ .state.p-danger-o label:after,
.pretty.p-toggle .state.p-danger-o label:after {
  background-color: transparent; }

.pretty input:checked ~ .state.p-danger-o .icon,
.pretty input:checked ~ .state.p-danger-o .svg,
.pretty input:checked ~ .state.p-danger-o svg,
.pretty.p-toggle .state.p-danger-o .icon,
.pretty.p-toggle .state.p-danger-o .svg,
.pretty.p-toggle .state.p-danger-o svg {
  color: #d9534f;
  stroke: #d9534f; }

.pretty.p-default:not(.p-fill) input:checked ~ .state.p-danger-o label:after {
  background-color: #d9534f !important; }

.pretty.p-switch input:checked ~ .state.p-danger:before {
  border-color: #d9534f; }

.pretty.p-switch.p-fill input:checked ~ .state.p-danger:before {
  background-color: #d9534f !important; }

.pretty.p-switch.p-slim input:checked ~ .state.p-danger:before {
  border-color: #a02622;
  background-color: #a02622 !important; }

.pretty.p-bigger label:before,
.pretty.p-bigger label:after,
.pretty.p-bigger .icon,
.pretty.p-bigger .svg,
.pretty.p-bigger .img {
  font-size: 1.2em !important;
  top: calc((0% - (100% - 1em)) - 35%) !important; }

.pretty.p-bigger label {
  text-indent: 1.7em; }

@media print {
  .pretty .state:before,
  .pretty .state label:before,
  .pretty .state label:after,
  .pretty .state .icon {
    color-adjust: exact;
    /* stylelint-disable */
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact; } }

.ProductsTable .ThumbnailImage {
  width: 60px;
  border: solid 1px rgba(0, 0, 0, 0.0625);
  border-radius: .25rem; }

.ProductsTable .ListItem {
  width: 100%; }

.ProductsTable td {
  vertical-align: top; }

.ProductsTable .SelectBar {
  background-color: #fff !important;
  border-color: transparent;
  border-top: 1px;
  border-bottom: 2px;
  position: relative; }
  .ProductsTable .SelectBar .Menu {
    position: absolute;
    display: inline-block;
    width: 100%;
    top: 0;
    left: 100%; }

.ProductAction {
  border-color: #0f9aee;
  position: relative;
  z-index: 99; }
  .ProductAction .ActionButton {
    display: block;
    background-color: transparent;
    border: 1px solid #0f9aee;
    color: #0f9aee;
    padding: .5rem 1rem;
    border-radius: 4px;
    outline: none;
    z-index: 99; }
  .ProductAction .ListAction {
    min-height: 3rem;
    width: 15rem;
    position: absolute;
    top: calc(100% + 0.5rem);
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 0 1px rgba(39, 44, 48, 0.05), 0 2px 7px 1px rgba(39, 44, 48, 0.16);
    overflow: hidden;
    display: none;
    z-index: 99; }
    .ProductAction .ListAction ul {
      list-style: none;
      padding: 0;
      width: 100%; }
      .ProductAction .ListAction ul li {
        display: block;
        font-weight: normal;
        cursor: pointer;
        padding: 0.5rem;
        width: 100%; }
        .ProductAction .ListAction ul li:hover {
          background: #007ace;
          color: #f4f6f8; }
  .ProductAction .Show {
    display: block; }
  .ProductAction .Background {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 98; }

.LoginPageContainer {
  background-color: #f5f5f5;
  min-height: 100vh;
  background-image: url(/static/media/login-bg.c9ab430e.jpeg); }
  .LoginPageContainer .Logo {
    width: 100px;
    height: 100px;
    background-image: url(/static/media/logo.237a5e71.png);
    background-size: contain;
    background-repeat: no-repeat; }
  .LoginPageContainer .LoginPageInner {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center; }
  .LoginPageContainer .Forms {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center; }
  .LoginPageContainer .LoginPageInner {
    background-color: #fff;
    width: 500px;
    max-width: 100%;
    min-height: 100vh;
    text-align: center; }
  .LoginPageContainer .PageTitle {
    font-size: 1.3125rem;
    font-weight: 300;
    color: #313435;
    margin-bottom: 30px;
    margin-top: 30px; }
  .LoginPageContainer .SocialLogin {
    background-color: #fff;
    outline: none;
    border-radius: 5px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 0 30px;
    height: 40px;
    border: solid 1px rgba(0, 0, 0, 0.0625);
    box-shadow: 1px 4px 5px 0px rgba(0, 0, 0, 0.1);
    -webkit-transition: all .3s ease;
    transition: all .3s ease; }
    .LoginPageContainer .SocialLogin .Icon {
      width: 18px;
      margin-right: 15px; }
    .LoginPageContainer .SocialLogin .Text {
      font-size: .875rem;
      font-weight: 700;
      color: #737373;
      line-height: 1; }

.StoresPage {
  min-height: 100vh;
  background-color: #f9fafb; }
  .StoresPage .PageTitle {
    margin-bottom: 20px; }
  .StoresPage .StoresPageInner {
    background-color: #fff;
    padding: 60px 40px;
    width: 700px;
    position: relative;
    top: 40px;
    border: solid 1px rgba(0, 0, 0, 0.0625); }

.StatisticsFilter {
  margin-bottom: 20px; }

.StatisticsRow {
  min-height: 100%; }

.ProductEditContainer .Header {
  display: block;
  width: 100%; }
  .ProductEditContainer .Header h1 {
    margin: 0; }

.ProductEditContainer .BottomButtons {
  width: 100%;
  margin-top: 1.5rem;
  border-top: 1px solid #ccc;
  padding: 1.5rem 0;
  display: inline-block; }
  .ProductEditContainer .BottomButtons .ButtonLeft {
    float: left; }
  .ProductEditContainer .BottomButtons .ButtonRight {
    float: right; }
  .ProductEditContainer .BottomButtons .ButtonWithIcon span {
    margin: 0 0.5rem; }
    .ProductEditContainer .BottomButtons .ButtonWithIcon span:first-child {
      margin-left: 0; }
    .ProductEditContainer .BottomButtons .ButtonWithIcon span:last-child {
      margin-right: 0; }

.OrganizationEdit .EditSection {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 0 !important; }
  .OrganizationEdit .EditSection .Title {
    font-size: 1.1rem;
    font-weight: 500;
    padding-left: 15px;
    padding-right: 15px; }
  .OrganizationEdit .EditSection .Block {
    border-top: 1px solid #dfe3e8;
    padding: 1rem 15px;
    margin: 0.5rem 0; }

.TagEdit {
  display: block; }
  .TagEdit .Tags {
    display: inline-block;
    margin: 0;
    width: 100%;
    list-style-type: none;
    padding: 0; }
    .TagEdit .Tags .Tag {
      display: block;
      float: left;
      margin-right: 0.5rem;
      margin-bottom: 5px;
      padding: 0.1rem 0.3rem;
      background-color: #dfe3e8;
      border-radius: 4px;
      color: #454f5b; }
      .TagEdit .Tags .Tag .CloseButton {
        margin-left: 0.5rem;
        cursor: pointer;
        color: #798c9c; }

.StatusEdit {
  margin-bottom: 1rem; }
  .StatusEdit .ChangeStatus .Link {
    color: #637381; }
  .StatusEdit .ChangeStatus .StatusButton {
    padding: 0.375rem 0.75rem;
    color: #637381;
    outline: none;
    margin-right: 1rem;
    border: 0;
    cursor: pointer;
    position: relative; }
    .StatusEdit .ChangeStatus .StatusButton:hover {
      background-color: rgba(33, 43, 54, 0.1); }
    .StatusEdit .ChangeStatus .StatusButton .Title {
      margin-right: 0.5rem; }
  .StatusEdit .ChangeStatus .Focus {
    background-color: rgba(33, 43, 54, 0.1); }
  .StatusEdit .ChangeStatus .StatusWrapper {
    position: absolute;
    z-index: 99;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    -webkit-transition: -webkit-transform .1s;
    transition: -webkit-transform .1s;
    transition: transform .1s;
    transition: transform .1s, -webkit-transform .1s;
    top: 2.5rem;
    left: 0; }
    .StatusEdit .ChangeStatus .StatusWrapper .Card {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content; }
      .StatusEdit .ChangeStatus .StatusWrapper .Card .CardBody {
        padding: .375rem 0; }
        .StatusEdit .ChangeStatus .StatusWrapper .Card .CardBody .StatusList {
          padding: 0;
          margin: 0;
          list-style: none; }
          .StatusEdit .ChangeStatus .StatusWrapper .Card .CardBody .StatusList .Status {
            padding: .375rem .75rem;
            text-align: left; }
            .StatusEdit .ChangeStatus .StatusWrapper .Card .CardBody .StatusList .Status:hover {
              background-color: #2196f3;
              color: #fff;
              cursor: pointer; }
  .StatusEdit .ChangeStatus .Hidden {
    -webkit-transform: scale(0);
            transform: scale(0); }
  .StatusEdit .ChangeStatus .Background {
    z-index: 98;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.CollectionsEdit {
  position: relative; }
  .CollectionsEdit .Form {
    z-index: 99; }
  .CollectionsEdit .CollectionSuggestion .Card {
    z-index: 99;
    width: 100%;
    position: absolute;
    overflow: hidden; }
    .CollectionsEdit .CollectionSuggestion .Card .Wrapper {
      padding: 0;
      min-height: 1rem;
      padding: 0.375rem 0; }
      .CollectionsEdit .CollectionSuggestion .Card .Wrapper .List {
        padding: 0;
        margin: 0;
        overflow: hidden;
        list-style: none;
        width: 100%; }
        .CollectionsEdit .CollectionSuggestion .Card .Wrapper .List .Suggestion {
          background-color: #ffffff;
          padding: .375rem .75rem;
          cursor: pointer; }
          .CollectionsEdit .CollectionSuggestion .Card .Wrapper .List .Suggestion:hover {
            background-color: #2196f3;
            color: #f4f6f8; }
        .CollectionsEdit .CollectionSuggestion .Card .Wrapper .List .Selected {
          background-color: #2196f3 !important;
          color: #f4f6f8; }
  .CollectionsEdit .Background {
    position: fixed;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 4; }
  .CollectionsEdit .Collections {
    padding: 0;
    margin: 0;
    width: 100%; }
    .CollectionsEdit .Collections .Collection {
      width: 100%;
      border-radius: 4px;
      padding: .375rem .75rem;
      display: inline-block; }
      .CollectionsEdit .Collections .Collection:hover {
        background: #ebeef0; }
      .CollectionsEdit .Collections .Collection .CloseButton {
        background-color: #f4f6f8;
        float: right;
        padding: 0 0.5rem;
        cursor: pointer;
        border-radius: 4px; }
        .CollectionsEdit .Collections .Collection .CloseButton:hover {
          background: #0f9d58;
          color: #f4f6f8; }

.DeleteWarning .Text {
  display: block; }

